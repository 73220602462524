<template>
    <div>
        <mobile-layout source="img" tab="1">
            <v-img src="../../assets/agenda.jpg"></v-img>
            <!-- <v-container>
                <v-row justify="center" no-gutters class="flex mt-10">
                    <v-col cols="12" align="center" justify="center">
                        <h1>大会议程</h1>
                    </v-col>
                    <span class="caption light-blue--text text--lighten-4 block"
                        >CONFERENCE AGENDA</span
                    >
                </v-row>
                <v-row justify="center" no-gutters class="flex mt-5">
                    <h2>09:00-11:00</h2>
                    &nbsp;
                    <span style="line-height: 40px">am</span>
                </v-row>
                <v-row
                    class="row-grid"
                    align="center"
                    justify="center"
                    :class="n == 2 ? 'mt-10' : 'mt-5'"
                    v-for="n in 2"
                    :key="n"
                >
                    <v-col
                        v-for="m in 2"
                        :key="m"
                        cols="5"
                        no-gutters
                        class="pa-0"
                        :class="m == 2 ? 'ml-3' : ''"
                    >
                        <v-card rounded="0" flat>
                            <v-img
                                src="../../assets/agenda_item_1.png"
                                class="white--text align-start"
                                content-class=""
                                gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                            >
                                <v-card-title
                                    class="justify-center mt-2"
                                    style="font-size: 1.15rem"
                                    >分论坛{{ n + m }}</v-card-title
                                >
                                <v-card-text class="subtitle-2 mt-n2"
                                    >医院高质量发展与<br />医械创新论坛</v-card-text
                                >
                            </v-img>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row
                    align="center"
                    justify="center"
                    class="mt-7 row-grid-once"
                >
                    <v-col cols="10">
                        <v-card rounded="0" flat>
                            <v-img
                                src="../../assets/agenda_item_2.png"
                                class="white--text"
                                height="200px"
                            >
                                <v-card-title
                                    class="justify-start mt-2"
                                    style="font-size: 1.15rem"
                                    >分论坛5</v-card-title
                                >
                                <v-card-text
                                    class="subtitle-2"
                                    style="position: absolute; bottom: 0"
                                    >医院高质量发展与<br />医械创新论坛</v-card-text
                                >
                            </v-img>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row justify="center" no-gutters class="flex mt-16">
                    <h2>14:00-16:00</h2>
                    &nbsp;
                    <span style="line-height: 40px">pm</span>
                </v-row>
                <v-row
                    align="center"
                    justify="center"
                    class="mt-2 mb-16 row-grid-once"
                >
                    <v-col cols="10">
                        <v-card rounded="0" flat>
                            <v-img
                                src="../../assets/agenda_item_1.png"
                                class="white--text align-start mb-16"
                                height="400"
                                position="center bottom"
                            >
                                <v-card-title
                                    class="h5 text-center justify-center mt-10"
                                    >中国医疗器械产业峰会<br />暨第二届国药器械生态大会</v-card-title
                                >
                            </v-img>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container> -->
        </mobile-layout>
    </div>
</template>

<script>
import MobileLayout from '../../components/MobileLayout.vue'
export default {
    components: { MobileLayout },
    name: 'Agenda',

    data: () => ({}),
}
</script>

<style lang="scss" scoped>
.v-application--is-ltr .offset-1 {
    // margin-left: 3.9%;
}

.row-grid {
    margin: -26px;
}

.row-grid-once {
    margin: -48px;
}
</style>
